<template>
	<div :class="themeClass" class="width-fill bg-F8F9FE">
		<div class="min-width1100 max-width1100 margin-lr-auto box-sizing">
			<!-- 开店表单 -->
			<div class="underReviewBg-img flex-row-center-center">
				<div class="underReview-box bg-fff radius10 flex-row-start-center padding-20">
					<div class="flex-row-start-center">
						<div v-if="type==1" class="underReview1-img"></div>
						<div v-else class="underReview1-img1"></div>
						<div class="flex-colum">
							<div v-if="type==1" class="flex-colum color-theme font-size20 font-weight700">
								<div class="margin-b-15">恭喜您成功注册为聚材通平台会员！</div>
							</div>
							<div v-else class="flex-colum color-theme font-size20 font-weight700">
								<div class="margin-b-15">资料审核中,请耐心等待！</div>
							</div>
							<div style="display: flex;">
								<router-link to="/">
										<div class="underReview-btn text-center border-color-theme color-theme">
											返回首页
										</div>
								</router-link>
								<div style="display: inline-block;justify-content:space-around;margin-left: 15px;">
									<div style="display: inline-block;cursor: pointer;" class="underReview-btn text-center border-color-theme color-theme" @click="helpCenter">前往帮助中心</div>
									<!-- <div class="underReview-btn text-center border-color-theme color-theme" style="margin-left: 15px;display: inline-block;cursor: pointer;" @click="Nohelp">暂时不用</div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions
	} from 'vuex';
	export default {
		data() {
			return {
				type: null ,//是否成功
				tipsVisible:false,
				
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			...mapActions(["setUser"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			console.log(this.$route.query);
			this.type = this.$route.query.type;
			if (this.type == 1) {
				this.getUserInfoSuccess()
			}
			//当企业认证提交后
			if(this.type == 2){
			}
		},
		methods: {
			//前往帮助中心
			helpCenter(){
				this.tipsVisible = false
				this.$router.push("/HelpCenter/HelpCenter")
			},
			//暂时不用
			Nohelp(){
				this.tipsVisible = false
			},
			getUserInfoSuccess() {
				this.ApiRequestPostNOMess(
					"/api/mall/ebcustomer/baseinfo/get-current-customer-user-info"
				).then(
					(result) => {
						this.$store.commit("setUserInfo", result.obj);
						this.$store.commit("setUser", result.obj);
						sessionStorage.setItem("setUserInfo", JSON.stringify(result.obj));

					},
					(rej) => {}
				);
			},
		}
	}
</script>

<style scoped lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	.underReview-box {
		width: 860px;
		height: 213px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	}

	.underReviewBg-img {
		width: 100%;
		height: 550px;
		// background-image: url(../../assets/imgs/HomePage/OpenShopUnderReviewBg.png);
		background-size: 100% 100%;
	}

	.underReview1-img {
		width: 180px;
		height: 180px;
		background-image: url(../../assets/imgs/HomePage/SHZ.png);
		background-size: 100% 100%;
		margin-right: 50px;
		margin-left: 50px;
	}

	.underReview1-img1 {
		width: 180px;
		height: 180px;
		background-image: url(../../assets/imgs/HomePage/RZCG.png);
		background-size: 100% 100%;
		margin-right: 50px;
		margin-left: 50px;
	}

	.underReview-btn {
		width: 160px;
		height: 40px;
		line-height: 40px;
	}
	.noticeTips {
		width: 800px;
		height: 180px;
		z-index: 2002;
		background-color: white;
	}
</style>
